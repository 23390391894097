<!-- 平台类 -->
<template>
  <section class="overhaul">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <!-- <el-row :gutter="30">
        <el-col :span="5">
          <div class="tabs">
            <p class="title"><span>平台类产品 </span></p>
            <p class="en">Digital Meter</p>
            <p class="btn"><el-button type="text" :class="showClass == 1 ? 'black' : 'grey'" @click="handleTextButtonClick(1)">变电站智慧辅控管理平台</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 2 ? 'black' : 'grey'" @click="handleTextButtonClick(2)">变电站远程智能巡视系统</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 3 ? 'black' : 'grey'" @click="handleTextButtonClick(3)">智能电子安防监控平台</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 4 ? 'black' : 'grey'" @click="handleTextButtonClick(4)">变电站表计数字化远程监视平台</el-button></p>
          </div>
        </el-col>
        <el-col :span="19"> -->
          <div class="main">
            <div class="title">
              <h2>{{ options.title }}</h2>
              <div class="line"></div>
              <p>{{ options.en }}</p>
              <!-- <p class="tit">{{ options.title1 ? options.title1 : options.title }}</p> -->
            </div>
            <ArticleContent :data="options.content" class="information-content" />
          </div>
        <!-- </el-col>
      </el-row> -->
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/2023/product/导航图.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {title: '', content: []},
      showClass: 1,
      id: 1
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      this.showClass = id
      switch (id) {
        case '1':
          this.options = this.$store.getters.getOverhaul1 && JSON.parse(JSON.stringify(this.$store.getters.getOverhaul1))
          break
        case '2':
          this.options = this.$store.getters.getOverhaul2 && JSON.parse(JSON.stringify(this.$store.getters.getOverhaul2))
          break
        case '3':
          this.options = this.$store.getters.getOverhaul3 && JSON.parse(JSON.stringify(this.$store.getters.getOverhaul3))
          break
        case '4':
          this.options = this.$store.getters.getOverhaul4 && JSON.parse(JSON.stringify(this.$store.getters.getOverhaul4))
          break
        default:
          this.options = this.$store.getters.getOverhaul1 && JSON.parse(JSON.stringify(this.$store.getters.getOverhaul1))
          break
      }
    },
    handleTextButtonClick (org) {
      if (org == this.id) return
      this.id = org
      this.showClass = org == 1 ? true : false
      let {...query} = this.$route.query;
      query.id = org
      this.$router.replace({query: {...query}});
    }
  }
}
</script>

<style scoped>
.overhaul {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {background-color: #ecf0f5; line-height: 2;}
.overhaul .title {text-align: center; padding: 1rem 0 5rem; line-height: 2; font-size: 2rem; color: #666;}
.overhaul .title .line {width: 2em; height: 2px; background-color: #e67700; margin: 0 auto;}
.overhaul .title  .tit {font-size: 1.2rem;}
.tabs .btn {text-align: left;}
.main {background-color: #fff; padding: 2rem 1rem;}
.main .title {text-align: center; font-size: 1.4rem; line-height: 2;}
.main .line {height: 1px; background-color: #e67700; width: 6em; margin: 0 auto 2rem;}
.black {color: rgba(0, 0, 0, 1); font-weight: 600;}
.grey {color: rgba(0, 0, 0, .5);;}

</style>